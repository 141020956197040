import {Component, inject, OnInit} from '@angular/core';
import {EntityPerformanceChartComponent, PerformanceRecord} from "./entity-performance-chart.component";
import {
    AggregatedDataPoint,
    DataType,
    Location,
    MeasurementsResult,
    Organisation,
    PortfolioMeasurementsResult
} from "@flowmaps/flowmaps-typescriptmodels";
import {DashboardContext} from '../../dashboard/dashboard.context';
import {lodash} from "../../../common/utils";
import {map, mergeMap, Observable, of} from "rxjs";
import {RefdataUtils} from "../../refdata/refdata-utils";
import {ActiveElement, Chart, ChartEvent} from "chart.js";
import {Router} from "@angular/router";
import {AppContext} from "../../../app-context";
import {sendQuery} from "../../../common/app-common-utils";
import {Entity, EntityType} from "../../../handlers/entity";
import {Dashboard} from "../../dashboard/dashboard.types";
import {LocationDashboardComponent} from "../../location-dashboard/location-dashboard.component";
import {HandleQuery} from "../../../common/handle-query";
import {Handler} from 'src/app/common/handler';
import {DataQueryFilters} from "../../measurements-component/measurements-handler.component";
import {PortfolioMeasurementsDataProvider} from "../../../utils/portfolio-measurements-data-provider";

@Component({
    selector: 'app-location-performance-chart',
    templateUrl: './entity-performance-chart.component.html',
    styleUrls: ['./entity-performance-chart.component.scss']
})
@Handler()
export class LocationPerformanceChartComponent extends EntityPerformanceChartComponent implements OnInit {
    private router: Router = inject(Router);

    perSquareMeterEnabled = true;

    entityType = (): EntityType => EntityType.location

    get getSelectedDataType() {
        return this.dataType || super.getSelectedDataType;
    }

    ngOnInit() {
        super.ngOnInit();
        this.subscribeTo("getPortfolioMeasurements").subscribe((d: PortfolioMeasurementsResult) => this.setData(d));
    }

    refresh = () => this.sendQuery("getPortfolioMeasurements")
        .subscribe((d: PortfolioMeasurementsResult) => this.setData(d));

    setDataProvider = (d: DataQueryFilters) => this.dataProvider = new PortfolioMeasurementsDataProvider(this.chartUtils, {
        timeRange: d.timeRange,
        predefinedTimeRange: d.timeRange.label,
        resolution: d.timeRange.resolution
    }, d.sources);

    possibleDataTypes = (): DataType[] =>
        [DataType.electricityConsumption, DataType.electricityFeedIn, DataType.electricityConsumptionReactive, DataType.electricityFeedInReactive, DataType.electricityGrossProduction]
            .concat([DataType.gasConsumption, DataType.heatConsumption, DataType.waterConsumption, DataType.coolingConsumption])
            .concat([DataType.electricityIntermediateConsumption, DataType.waterIntermediateConsumption])
            .concat(AppContext.isAdmin() ? [DataType.gasConsumptionUncorrected] : []);

    createData = (result: PortfolioMeasurementsResult): Observable<PerformanceRecord[]> => {
        return this.sendQuery("getByLocation", result)
            .pipe(mergeMap((byLocation: MeasurementsResult[]) =>
                this.getEntities().pipe(map(entities =>
                    Object.entries(lodash.groupBy(byLocation, d => d.entityId))
                        .map(e => {
                            const entity = entities.find(l => l.location.locationId === e[0] || l.location.aliasIds.includes(e[0]));
                            if (!entity) {
                                return null;
                            }
                            const area = RefdataUtils.getLocationArea(entity.location);
                            const value = lodash.sum(e[1].flatMap(d => this.measurementTypes()
                                .flatMap(m => this.getMeasurementsForType(d.measurements, m) || [])
                                .map(r => r.value)));
                            const estimatedValue = lodash.sum(e[1].flatMap(d => this.measurementTypes()
                                .flatMap(m => this.getMeasurementsForType(d.estimatedMeasurements, m) || [])
                                .map(r => r.value)));
                            return entity ? {
                                entityId: e[0],
                                value: this.showPerSquareMeter() ? (area ? lodash.round(value / area, 1) : null) : value,
                                estimatedValue: this.showPerSquareMeter() ? (area ? lodash.round(estimatedValue / area, 1) : null) : estimatedValue,
                                label: RefdataUtils.addressFormatter(entity.location.info.address, false)
                            } : null;
                        }).filter(e => e)))));
    }

    private getEntities = (): Observable<Entity[]> => {
        return this.sendQuery("getChartDataQueryFilters").pipe(mergeMap((q: DataQueryFilters) => {
            const selectedEntities: Entity[] = q.sources?.filter(t => t.type === this.entityType())
                .map(t => t.source) || [];
            return selectedEntities.length > 0 ? of(selectedEntities) : this.getAllEntities();
        }))
    }

    getAllEntities = (): Observable<Entity[]> => sendQuery("getLocationsAsEntities")

    navigateToEntity = (event: ChartEvent, elements: ActiveElement[], chart: Chart) => {
        const points = chart.getElementsAtEventForMode(event.native, 'nearest', {intersect: true}, true);

        if (points && elements.length > 0) {
            const clickedRecord: PerformanceRecord = this.currentPageRecords[points[0].index];
            const locationDashboard: Dashboard = LocationDashboardComponent.defaultDashboard();
            locationDashboard.info.sources.locationIds = [clickedRecord.entityId];
            this.router.navigateByUrl(`/location/dashboard/${DashboardContext.dashboardToBase64(locationDashboard)}`);
        }
    }

    onHover = (event: ChartEvent, elements: ActiveElement[], chart: Chart) => {
        const target: any = event.native.target;
        target.style.cursor = elements.length > 0 ? "pointer" : "auto";
    }

    private getMeasurementsForType(m: { [P in DataType]?: AggregatedDataPoint[] }, dataType: DataType): AggregatedDataPoint[] {
        const measurements = m[dataType] || [];
        if (dataType === DataType.heatConsumption) {
            return measurements.map(m => (<AggregatedDataPoint>{
                timeRange: m.timeRange,
                value: m.value * DashboardContext.gjToM3Rate
            }));
        }
        return measurements;
    }

    private mergeAliasesWithLocation(data: MeasurementsResult[], organisations: Organisation[]): MeasurementsResult[] {
        return data.map(r => {
            const location = this.getLocation(organisations, r.entityId);
            if (!location || location.locationId !== r.entityId) {
                return null;
            }
            if (!location.aliasIds?.length) {
                return r;
            }
            const aliasResults = data.filter(a => location.aliasIds.includes(a.entityId));
            aliasResults.forEach(v => r = this.mergeMeasurementsResult(r, v))
            return r;
        }).filter(r => r);
    }

    private mergeMeasurementsResult = (destination: MeasurementsResult, other: MeasurementsResult): MeasurementsResult => {
        destination.measurements = this.mergeMeasurements(destination.measurements, other.measurements);
        destination.estimatedMeasurements = this.mergeMeasurements(destination.estimatedMeasurements, other.estimatedMeasurements);
        return destination;
    }

    private mergeMeasurements(destination: { [P in DataType]?: AggregatedDataPoint[] }, other: { [P in DataType]?: AggregatedDataPoint[] }): { [P in DataType]?: AggregatedDataPoint[] } {
        const findByTimeRange = (data: AggregatedDataPoint[], a: AggregatedDataPoint): AggregatedDataPoint => data.find(
            d => d.timeRange.start === a.timeRange.start && d.timeRange.end === a.timeRange.end);
        Object.entries(other).forEach(e => {
            const destinationValue: AggregatedDataPoint[] = destination[e[0]];
            if (!destinationValue) {
                destination[e[0]] = e[1];
                return;
            }
            e[1].forEach(d => {
                const destinationPoint = findByTimeRange(destinationValue, d);
                if (!destinationPoint) {
                    destinationValue.push(d);
                    return;
                }
                const aggregationMethod = AppContext.getAggregationMethod(e[0] as DataType);
                destinationPoint.value = aggregationMethod([destinationPoint.value, d.value]);
            });
            destination[e[0]] = destinationValue;
        });
        return destination;
    }

    private getLocation = (organisations: Organisation[], id: string): Location => organisations.flatMap(
        o => o.locations.filter(l => l.locationId === id || l.aliasIds?.includes(id)))[0];

    @HandleQuery("getByLocation")
    getByLocation(result: PortfolioMeasurementsResult): Observable<MeasurementsResult[]> {
        return this.sendQuery("getOrganisations")
            .pipe(map((o: Organisation[]) => this.mergeAliasesWithLocation(lodash.cloneDeep(result.byLocation || []), o)))
            .pipe(map(r =>
                (r || []).map(l => {
                    const measurements: { [P in DataType]?: AggregatedDataPoint[] } = {};
                    Object.entries(l.measurements)
                        .filter(e => this.measurementTypes().includes(e[0] as DataType))
                        .forEach(e => measurements[e[0]] = e[1]);
                    const estimatedMeasurements: { [P in DataType]?: AggregatedDataPoint[] } = {};
                    Object.entries(l.estimatedMeasurements)
                        .filter(e => this.measurementTypes().includes(e[0] as DataType))
                        .forEach(e => estimatedMeasurements[e[0]] = e[1]);
                    return <MeasurementsResult>{
                        entityId: l.entityId,
                        measurements: measurements,
                        estimatedMeasurements: estimatedMeasurements
                    };
                })));
    }
}
