import {Component, Input, OnInit} from '@angular/core';
import {EntityPerformanceChartComponent, PerformanceRecord} from "./entity-performance-chart.component";
import {DataType, LocationMeasurementsResult} from "@flowmaps/flowmaps-typescriptmodels";
import {lodash} from "../../../common/utils";
import {map, mergeMap, Observable, of} from "rxjs";
import {RefdataUtils} from "../../refdata/refdata-utils";
import {Entity, EntityType} from "../../../handlers/entity";
import {sendQuery} from "../../../common/app-common-utils";
import {Measurements} from "../../measurements-component/measurements";
import {DataQueryFilters} from "../../measurements-component/measurements-handler.component";
import {Handler} from "../../../common/handler";
import {MeterMeasurementsDataProvider} from "../../../utils/meter-measurements-data-provider";

@Component({
    selector: 'app-meter-performance-chart',
    templateUrl: './entity-performance-chart.component.html',
    styleUrls: ['./entity-performance-chart.component.scss']
})
@Handler()
export class MeterPerformanceChartComponent extends EntityPerformanceChartComponent implements OnInit {
    @Input() selectedDataType: DataType = DataType.electricityIntermediateConsumption;

    ngOnInit() {
        super.ngOnInit();
        this.subscribeTo("getLocationMeasurements").subscribe((d: LocationMeasurementsResult) => this.setData(d));
    }

    get getSelectedDataType() {
        const selectedDataType = super.getSelectedDataType;
        return selectedDataType && this.possibleDataTypes().includes(selectedDataType) ? selectedDataType : this.selectedDataType;
    }

    entityType = (): EntityType => EntityType.meter

    refresh = () => this.sendQuery("getLocationMeasurements")
        .subscribe((d: LocationMeasurementsResult) => this.setData(d));

    setDataProvider = (d: DataQueryFilters) => this.dataProvider = new MeterMeasurementsDataProvider(this.chartUtils, {
        timeRange: d.timeRange,
        predefinedTimeRange: d.timeRange.label,
        resolution: d.timeRange.resolution
    }, d.sources);

    possibleDataTypes = (): DataType[] =>
        [DataType.electricityIntermediateConsumption, DataType.electricityGrossProduction, DataType.electricityGrossProductionReactive]
            .concat([DataType.gasConsumption, DataType.heatConsumption, DataType.waterConsumption])
            .concat([DataType.electricityConsumptionCosts, DataType.gasConsumptionCosts, DataType.waterConsumptionCosts,
                DataType.heatConsumptionCosts])
            .concat([DataType.co2EmissionFromElectricity, DataType.co2EmissionFromGas, DataType.co2EmissionFromWater,
                DataType.co2EmissionFromHeat]);

    createData = (result: LocationMeasurementsResult): Observable<PerformanceRecord[]> => {
        return this.sendQuery("getChartDataQueryFilters").pipe(mergeMap((d: DataQueryFilters) =>
            this.getEntities().pipe(map(meters => this.filterMetersBasedOnSelectedDataType(meters)))
                .pipe(map(meters => {
                    const filteredData = result?.byMeter?.length ? result?.byMeter.map(
                        m => new Measurements(m, this.getSelectedDataType, d.timeRange)) : [];
                    return Object.entries(lodash.groupBy(filteredData, d => d.data.entityId))
                        .map(e => ({
                            meter: meters.find(l => l.meter.meterId === e[0]),
                            dataset: e[1]
                        }))
                        .filter(e => e.meter)
                        .map(e => ({
                            entityId: e.meter.meter.meterId,
                            value: lodash.sum(e.dataset.flatMap(d => d.groupMeasurements())),
                            estimatedValue: null,
                            label: RefdataUtils.meterFormatter(e.meter)
                        }));
                }))));
    }

    private getEntities = (): Observable<Entity[]> => {
        return this.sendQuery("getChartDataQueryFilters").pipe(mergeMap((q: DataQueryFilters) => {
            const selectedEntities: Entity[] = q.sources?.filter(t => t.type === EntityType.location)
                .flatMap(t => t.source.location.connections
                    .flatMap(c => c.meters
                        .map(m => new Entity(t.source.organisation, t.source.location, c, m)))) || [];
            return selectedEntities.length > 0 ? of(selectedEntities) : this.getAllEntities();
        }))
    }

    getAllEntities = (): Observable<Entity[]> => sendQuery("getMetersAsEntities");

    filterMetersBasedOnSelectedDataType = (entities: Entity[]) => {
        switch (this.getSelectedDataType) {
            case DataType.electricityIntermediateConsumption:
            case DataType.electricityGrossProduction:
            case DataType.electricityGrossProductionReactive:
            case DataType.electricityConsumptionCosts:
            case DataType.co2EmissionFromElectricity:
                return entities.filter(e => e.meter && e.meter.info.type !== "PRIMARY" && e.connection?.info.connectionType === "Electricity");
            case DataType.gasConsumption:
            case DataType.gasConsumptionCosts:
            case DataType.co2EmissionFromGas:
                return entities.filter(e => e.meter && e.meter.info.type !== "PRIMARY" && e.connection?.info.connectionType === "Gas");
            case DataType.heatConsumption:
            case DataType.heatConsumptionCosts:
            case DataType.co2EmissionFromHeat:
                return entities.filter(e => e.meter && e.meter.info.type !== "PRIMARY" && e.connection?.info.connectionType === "Heat");
            case DataType.waterConsumption:
            case DataType.waterConsumptionCosts:
            case DataType.co2EmissionFromWater:
                return entities.filter(e => e.meter && e.meter.info.type !== "PRIMARY" && e.connection?.info.connectionType === "Water");
            default:
                return entities;
        }
    }
}
