import {
    Connection,
    ConnectionType,
    DataType,
    MeterType,
    Organisation,
    Role,
    TimeRange,
    TimeResolution,
    UserProfile
} from "@flowmaps/flowmaps-typescriptmodels";
import {AppCommonUtils} from "./common/app-common-utils";
import connectionTypes from "./resources/connection-types.json";
import meterTypes from "./resources/meter-types.json";
import measurementChartColors from "./resources/measurement-chart-colors.json";
import moment from "moment";
import {Rgba} from "./common/rgba";
import {Entity} from "./handlers/entity";
import {localTimeFormat} from "./common/utils";
import {TranslateDirective} from "./common/utils/translate.directive";
import {SourceInfo} from "./utils/source-providers/sources-provider";
import lodash, {Dictionary} from "lodash";
import {MeterViewEntity} from "./handlers/meter-views-standalone-handler";

export class AppContext {

    static brand = AppContext.getBrand();
    static brandName = AppContext.getBrandName();
    static userProfile: UserProfile;
    static initials: string;
    static displayFormat: string = "DD MMM YYYY";
    static defaultIsoFormat: string = "YYYY-MM-DD[T]HH:mm:ss[Z]";
    static supportedResolutions: TimeResolution[] = [TimeResolution.year, TimeResolution.month, TimeResolution.day, TimeResolution.hour, TimeResolution.minute];
    static impersonator: string;
    static weatherTypes: DataType[] = [
        DataType.temperature,
        DataType.windSpeed,
        DataType.rainfall,
        DataType.sunHours,
        DataType.solarRadiation
    ];
    static connectionTypes: ConnectionType[] = [ConnectionType.Electricity, ConnectionType.Gas, ConnectionType.Water, ConnectionType.Heat, ConnectionType.Cooling];
    static productionMeasurements: DataType[] = [DataType.electricityFeedIn, DataType.electricityFeedInOffPeak];

    static vanDorpIntermediaryId: string = "bfd2640bb7583a909f65c6edc84f6cb0";

    static today = (): string => moment().startOf("day").toISOString();
    static getYearsFrom = (year: number): number[] => lodash.range(year, moment().year(), 1);

    static isHeadlessChrome() {
        const userAgent = navigator.userAgent;
        return /HeadlessChrome/.test(userAgent);
    }

    private static getBrand() : string {
        if (this.userProfile?.intermediaryId) {
            if (this.userProfile.intermediaryId === this.vanDorpIntermediaryId) {
                return "vandorp";
            }
        }
        switch (location.hostname) {
            case 'ebs.vandorp.eu': return 'vandorp';
            default: return 'flowmaps';
        }
    }

    private static getBrandName() : string {
        if (this.userProfile?.intermediaryId) {
            if (this.userProfile.intermediaryId === this.vanDorpIntermediaryId) {
                return 'Van Dorp | energie';
            }
        }
        switch (location.hostname) {
            case 'ebs.vandorp.eu': return 'Van Dorp | energie';
            default: return 'Flowmaps';
        }
    }

    static setUserProfile = (userProfile: UserProfile) => {
        if (!userProfile) {
            AppCommonUtils.clearCache();
            localStorage.removeItem("Authorization");
        }
        this.userProfile = userProfile;
        this.initials = userProfile && (userProfile.info.firstName + ' ' + userProfile.info.lastName)
            .match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase();
        this.impersonator = userProfile && localStorage.getItem("impersonator");
        this.refreshBrand();
    };

    static refreshBrand = (): void => {
        this.brand = this.getBrand();
        this.brandName = this.getBrandName();
        ['16', '32', '96'].forEach(size => {
            const link : any = document.createElement('link');
            link.sizes = size + 'x' + size;
            link.rel = 'icon';
            link.type = "image/png";
            link.href = "assets/images/" + AppContext.brand + "-favicon-" + size + ".png";
            document.head.appendChild(link);
        });
        document.head.querySelector('title').text = this.brandName;
    }

    static getPreferredLanguage() {
        return this.userProfile?.info.language || localStorage.getItem('lastLanguage') || AppContext.getSystemLanguage() || 'en';
    }

    static initialiseLanguage(language: string) {
        localStorage.setItem('lastLanguage', language);
    }

    static getSystemLanguage(): string {
        let result = navigator.language?.split("-")[0];
        if (result) {
            switch (result) {
                case 'en':
                case 'nl':
                    return result;
                default:
                    return 'en';
            }
        }
        return null;
    }

    // TODO: Remove and move over to the authentication utils
    static isAdmin(): boolean {
        return AppContext?.userProfile?.userRole === Role.admin;
    }

    static isAdminOrIntermediary(): boolean {
        return this.isAdmin() || this.isManagerForOrganisationIds([AppContext?.userProfile?.intermediaryId]);
    }

    static isManagerForOrganisation(organisation: Organisation): boolean {
        return this.isManagerForOrganisationIds([organisation.intermediaryId, organisation.organisationId]);
    }

    static isManagerForEntity(entity: Entity): boolean {
        return this.isManagerForOrganisationIds([entity.organisation.intermediaryId, entity.organisation.organisationId]);
    }

    private static isManagerForOrganisationIds(organisationIds: any[]): boolean {
        if (AppContext.isAdmin()) {
            return true;
        }
        const allowedRoles = [Role.manager, Role.owner, Role.intermediary];
        return AppContext?.userProfile?.receivedAuthorisations
            .filter(a => a.entityIds.length === 0).filter(a => !a.revoked)
            .some(a => allowedRoles.indexOf(a.role) >= 0 && organisationIds.indexOf(a.nominator) >= 0)
    }

    // TODO: Make queries from here
    static isWeatherType = (dataType: DataType): boolean => this.weatherTypes.includes(dataType);

    static getAggregationMethod = (dataType: DataType): (values: number[]) => number => this.isWeatherType(dataType) ? lodash.mean : lodash.sum;


    static timeRangeToQuery(timeRange: TimeRange): TimeRange {
        return timeRange ? {
            start: moment(timeRange.start).format(localTimeFormat),
            end: moment(timeRange.end).format(localTimeFormat)
        } : null;
    }

    static getIconOfSourceType = (sourceType: SourceType | string, connectionType: ConnectionType = null, meterType: MeterType = null): string => {
        switch (sourceType) {
            case SourceType.organisation:
                return "bi-diagram-3";
            case SourceType.location:
                return "bi-geo-alt";
            case SourceType.connection:
                return this.getConnectionConfig(connectionType).bootstrapIcon;
            case SourceType.meter:
                return this.getMeterConfig(meterType).bootstrapIcon;
            case "user":
                return "bi-people-fill";
            case "alert":
                return "bi-exclamation-triangle";
        }
    }

    static getChartColorForMeasurement = (type: DataType): Rgba => Rgba.fromString(measurementChartColors[type as string]);

    static getChartColorForMeasurementAsString = (type: DataType): string => measurementChartColors[type as string];

    static getConnectionConfig = (type: ConnectionType): MeasurementConfig => connectionTypes[type as string];

    static getMeterConfig = (type: MeterType): MeasurementConfig => meterTypes[type as string];

    static meterImage = (type: string): string => AppContext.getMeterConfig(type as MeterType).imagePath;

    static meterName = (type: string): string => AppContext.getMeterConfig(type as MeterType).name;

    static connectionImage = (connectionType: string): string => AppContext.getConnectionConfig(connectionType as ConnectionType).imagePath;

    static connectionName = (connectionType: string): string => AppContext.getConnectionConfig(connectionType as ConnectionType).name;

    static connectionIconColor = (connection: ConnectionType): string => Rgba.fromString(AppContext.getConnectionConfig(connection as ConnectionType).color).toString();

    static connectionIconColorFromString = (connection: string): string => this.connectionIconColor(connection as ConnectionType);

    static entityPerformanceMeasurementName = (measurementType: DataType, translate: boolean = true): string => {
        let name;
        switch (measurementType) {
            case DataType.electricityConsumptionReactive:
                name = "Electricity reactive";
                break;
            case DataType.co2EmissionFromElectricity:
                name = "CO² from electricity";
                break;
            case DataType.co2EmissionFromGas:
                name = "CO² from gas";
                break;
            case DataType.co2EmissionFromHeat:
                name = "CO² from heat";
                break;
            case DataType.co2EmissionFromWater:
                name = "CO² from water";
                break;
            case DataType.electricityConsumptionCosts:
                name = "Electricity costs";
                break;
            case DataType.gasConsumptionCosts:
                name = "Gas costs";
                break;
            case DataType.heatConsumptionCosts:
                name = "Heat costs";
                break;
            case DataType.waterConsumptionCosts:
                name = "Water costs";
                break;
            default:
                name = AppContext.measurementName(measurementType, translate);
                break;
        }
        return translate ? TranslateDirective.getTranslation(name.trim().toLowerCase(), name) : name;
    }

    static measurementName = (measurementType: DataType, translate: boolean = true): string => {
        let name;
        switch (measurementType) {
            case DataType.electricityConsumption:
                name = "Electricity consumption";
                break;
            case DataType.electricityConsumptionOffPeak:
                name = "Electricity consumption off-peak";
                break;
            case DataType.electricityConsumptionReactive:
                name = "Electricity reactive consumption";
                break;
            case DataType.electricityConsumptionReactiveOffPeak:
                name = "Electricity reactive consumption off-peak";
                break;
            case DataType.electricityIntermediateConsumption:
                name = "Electricity intermediate consumption";
                break;
            case DataType.electricityIntermediateConsumptionReactive:
                name = "Electricity reactive intermediate consumption";
                break;
            case DataType.electricityFeedIn:
                name = "Electricity feed-in";
                break;
            case DataType.electricityFeedInOffPeak:
                name = "Electricity feed-in off-peak";
                break;
            case DataType.electricityFeedInReactive:
                name = "Electricity reactive production";
                break;
            case DataType.electricityFeedInReactiveOffPeak:
                name = "Electricity reactive production off-peak";
                break;
            case DataType.electricityGrossProduction:
                name = "Electricity gross production";
                break;
            case DataType.electricityGrossProductionReactive:
                name = "Electricity reactive gross production";
                break;
            case DataType.electricityPower:
                name = "Electricity power";
                break;
            case DataType.electricityFeedInPower:
                name = "Electricity feed-in power";
                break;
            case DataType.gasConsumption:
                name = "Gas consumption";
                break;
            case DataType.gasConsumptionUncorrected:
                name = "Gas consumption uncorrected";
                break;
            case DataType.gasPower:
                name = "Gas power";
                break;
            case DataType.waterConsumption:
                name = "Water consumption";
                break;
            case DataType.waterIntermediateConsumption:
                name = "Water intermediate consumption";
                break;
            case DataType.waterPower:
                name = "Water power";
                break;
            case DataType.heatConsumption:
                name = "Heat consumption";
                break;
            case DataType.heatPower:
                name = "Heat power";
                break;
            case DataType.coolingConsumption:
                name = "Cooling consumption";
                break;

            // Weather
            case DataType.windSpeed:
                name = "Wind speed";
                break;
            case DataType.rainfall:
                name = "Rainfall";
                break;
            case DataType.temperature:
                name = "Temperature";
                break;
            case DataType.sunHours:
                name = "Sun hours";
                break;
            case DataType.solarRadiation:
                name = "Solar radiation";
                break;

            // CO2
            case DataType.co2EmissionFromElectricity:
                name = "Electricity";
                break;
            case DataType.co2EmissionFromGas:
                name = "Gas";
                break;
            case DataType.co2EmissionFromWater:
                name = "Water";
                break;
            case DataType.co2EmissionFromHeat:
                name = "Heat";
                break;

            // Costs
            case DataType.electricityConsumptionCosts:
                name = "Electricity";
                break;
            case DataType.gasConsumptionCosts:
                name = "Gas";
                break;
            case DataType.waterConsumptionCosts:
                name = "Water";
                break;
            case DataType.heatConsumptionCosts:
                name = "Heat";
                break;

            default:
                name = measurementType;
                break;
        }
        return translate ? TranslateDirective.getTranslation(name.trim().toLowerCase(), name) : name;
    }

    static resolutionForTimeRange = (timeRange: TimeRange): TimeResolution => {
        const duration = this.durationForTimeRange(timeRange);
        return duration.asYears() > 3
            ? TimeResolution.year
            : (duration.asMonths() > 3
                ? TimeResolution.month
                : (duration.asDays() > 2
                    ? TimeResolution.day
                    : TimeResolution.hour));
    }

    static durationForTimeRange = (timeRange: TimeRange): moment.Duration => {
        const startDate = moment(timeRange.start);
        const endDate = moment(timeRange.end);
        return moment.duration(endDate.diff(startDate));
    }

    // TODO: Completeness, make Queries from this
    static computeCompletenessPerTimeRange = (meterViews: MeterViewEntity[], timeRange: TimeRange, emptyConnections: Connection[]): CompletenessInfo[] => {
        if (!meterViews.length && !emptyConnections.length) {
            return [];
        }
        const diffMinutes = (start: Date, end: Date) => (end.getTime() - start.getTime()) / (1000 * 60);
        const startDate = new Date(timeRange.start);
        const endDate = new Date(timeRange.end);
        const completeness: Dictionary<CompletenessInfo> = {};
        meterViews?.forEach(v => {
            const viewStartDate: Date = this.computeMeterViewStartDate(v, startDate);
            const viewEndDate: Date = this.computeMeterViewEndDate(v, endDate);
            const totalMinutes = diffMinutes(viewStartDate, viewEndDate);
            const meterMinutes = lodash.sum(v.meterView.dataPeriods
                .map(p => {
                    let start = p.start;
                    let end = p.end;
                    if (p.end <= viewStartDate || p.start >= viewEndDate) {
                        return 0;
                    }
                    if (p.start < viewStartDate) {
                        start = viewStartDate;
                    }
                    if (p.end > viewEndDate) {
                        end = viewEndDate;
                    }
                    return diffMinutes(start, end);
                }));
            const meterCompleteness = totalMinutes <= 0 ? null : meterMinutes / totalMinutes;
            if (meterCompleteness != null && v.entity?.connection) {
                if (!completeness[v.entity.connection.connectionId]) {
                    completeness[v.entity.connection.connectionId] = {
                        connectionId: v.entity.connection.connectionId,
                        connectionType: v.entity.connection.info.connectionType as ConnectionType,
                        meterCompleteness: []
                    };
                }
                completeness[v.entity.connection.connectionId].meterCompleteness.push({
                    completeness: meterCompleteness,
                    meterView: v
                });
            }
        });
        emptyConnections.forEach(c => {
            if (!completeness[c.connectionId]) {
                completeness[c.connectionId] = {
                    connectionId: c.connectionId,
                    connectionType: c.info.connectionType as ConnectionType,
                    meterCompleteness: []
                };
                completeness[c.connectionId].meterCompleteness.push({
                    meterView: null,
                    completeness: 0
                })
            }
        });
        return Object.values(completeness);
    }

    static computeMeterViewStartDate(meterView: MeterViewEntity, queryStartDate: Date): Date {
        if (!meterView.meterView) {
            return queryStartDate;
        }
        const lowestStartDate = lodash.min(meterView.meterView.dataPeriods.map(d => d.start));
        let usedStartDate: Date = null;
        if (meterView.activeRange?.start) {
            usedStartDate = lodash.min([meterView.activeRange.start as any, lowestStartDate]);
        } else {
            usedStartDate = lowestStartDate;
        }
        return lodash.max([queryStartDate, usedStartDate]);
    }

    static computeMeterViewEndDate(meterView: MeterViewEntity, queryEndDate: Date): Date {
        if (!meterView.meterView) {
            return queryEndDate;
        }
        const highestEndDate = lodash.max(meterView.meterView.dataPeriods.map(d => d.end).concat(moment().subtract(1, 'day').startOf('day').toDate()));
        let usedEndDate: Date = null;
        if (meterView.authorizedRange?.end) {
            usedEndDate = lodash.max([highestEndDate, meterView.authorizedRange.end]) as Date;
        } else if (meterView.activeRange?.end) {
            return lodash.min([queryEndDate, highestEndDate, meterView.activeRange.end]);
        } else {
            usedEndDate = highestEndDate;
        }
        return lodash.min([queryEndDate, usedEndDate, highestEndDate]);
    }

    static computeCompleteness = (meterViews: MeterViewEntity[], timeRange: TimeRange, emptyConnections: Connection[]): number =>
        meterViews.length ? this.computeCompletenessFromInfo(
            this.computeCompletenessPerTimeRange(meterViews, timeRange, emptyConnections)) : 0;

    static computeCompletenessFromInfo = (completenessInfo: CompletenessInfo[]): number => {
        const meterCompleteness = completenessInfo?.map(c => c.meterCompleteness?.length
            ? lodash.sum(c.meterCompleteness.map(mc => mc.completeness)) / c.meterCompleteness.length : null)
            .filter(n => n !== null);
        return meterCompleteness?.length ? lodash.sum(meterCompleteness) / meterCompleteness.length : 0;
    };


    static completenessTitle = (completeness: CompletenessInfo[], selection: SourceInfo[], connectionTypes: ConnectionType[] | string[]): string => {
        const connectionTypesString = connectionTypes.filter(c => c).map(c => TranslateDirective.getTranslation(c, true).toLowerCase()).join(" & ");
        return `${TranslateDirective.getTranslation("Total completeness for", true)} ${connectionTypesString}: ${this.completeness(completeness, selection, connectionTypes)}%`;
    };

    static completeness = (completeness: CompletenessInfo[], selection: SourceInfo[], connectionTypes: ConnectionType[] | string[]): number => {
        if (!completeness?.length) {
            return 0;
        }
        const selectedConnections = selection.filter(s => connectionTypes.includes(s.connectionType)).flatMap(c => [c.id, ...c.aliases]);
        return lodash.round(AppContext.computeCompletenessFromInfo(completeness.filter(
            c => connectionTypes.includes(c.connectionType) && selectedConnections.includes(c.connectionId))) * 100, 1);
    }

    static completenessColor = (completeness: CompletenessInfo[], selection: SourceInfo[], connectionTypes: ConnectionType[] | string[]): string =>
        Rgba.RED.transparency(0.7).interpolate(Rgba.GREEN.transparency(0.7).darken(0.5),
            this.completeness(completeness, selection, connectionTypes) / 100).toString();

    // TODO: Get ordering based on query
    static indexOfDataType = (measurementType: DataType): number => (this.sortedDataTypes.indexOf(measurementType) + 1) * 100;

    private static sortedDataTypes: DataType[] = [
        // Consumption
        DataType.electricityGrossProduction,
        DataType.electricityConsumption,
        DataType.electricityConsumptionOffPeak,
        DataType.electricityConsumptionReactive,
        DataType.electricityIntermediateConsumption,
        DataType.electricityIntermediateConsumptionReactive,
        DataType.electricityFeedInReactive,
        DataType.electricityConsumptionReactiveOffPeak,
        DataType.electricityFeedInReactiveOffPeak,
        DataType.electricityGrossProductionReactive,
        // Production
        DataType.electricityFeedIn,
        DataType.electricityFeedInOffPeak,
        // Power
        DataType.electricityPower,
        DataType.electricityFeedInPower,
        // Heat
        DataType.gasConsumption,
        DataType.gasConsumptionUncorrected,
        DataType.heatConsumption,
        DataType.gasPower,
        DataType.heatPower,
        // Water
        DataType.waterConsumption,
        DataType.waterIntermediateConsumption,
        DataType.waterPower,
        // Weather
        DataType.rainfall,
        DataType.solarRadiation,
        DataType.sunHours,
        DataType.temperature,
        DataType.windSpeed,
        // CO2
        DataType.co2EmissionFromElectricity,
        DataType.co2EmissionFromGas,
        DataType.co2EmissionFromHeat,
        DataType.co2EmissionFromWater,
        // Costs
        DataType.electricityConsumptionCosts,
        DataType.electricityEnergyTax,
        DataType.electricityVat,
        DataType.gasConsumptionCosts,
        DataType.gasEnergyTax,
        DataType.gasVat,
        DataType.heatConsumptionCosts,
        DataType.heatEnergyTax,
        DataType.heatVat,
        DataType.waterConsumptionCosts,
        DataType.waterEnergyTax,
        DataType.waterVat,
    ]
}

export interface MeasurementConfig {
    name: string;
    icon: string;
    bootstrapIcon: string;
    imagePath: string;
    color: string;
    costsColors: {[key: string]: string};
}

export interface CompletenessInfo {
    connectionId: string;
    connectionType: ConnectionType;
    meterCompleteness: MeterCompletenessInfo[];
}

interface MeterCompletenessInfo {
    meterView: MeterViewEntity;
    completeness: number;
}

export enum SourceType {
    organisation = "organisation",
    location = "location",
    connection = "connection",
    meter = "meter"
}