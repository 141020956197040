import {Component, inject, Input, NgZone, OnInit} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {DateRange, LinkContract, LinkedContract, Location, SourceIds} from "@flowmaps/flowmaps-typescriptmodels";
import {uuid} from "../../../../common/utils";
import {SourceInfo, TreeViewItem} from "../../../../utils/source-providers/sources-provider";
import {ContractEntity} from "../../../../handlers/app-entity-handler.service";
import {OrganisationProvider} from "../../../../utils/source-providers/organisation-provider";
import {Entity, EntityType} from "../../../../handlers/entity";

@Component({
    selector: 'app-link-contract-details',
    templateUrl: './link-contract-details.component.html',
    styleUrls: ['./link-contract-details.component.scss']
})
@Handler()
export class LinkContractDetailsComponent extends View implements OnInit {
    @Input() data: LinkContractInBulkData;
    sourceProvider: OrganisationProvider;
    addresses: Location[] = [];
    sources: SourceIds;
    connections: Entity[] = []

    private ngZone: NgZone = inject(NgZone);

    ngOnInit() {
        this.sourceProvider = new OrganisationProvider('', [], (item: SourceInfo) => {
            const connectionType = this.data.contract.contract.contractData.connectionType;
            return item.connectionType === connectionType || !item.connectionType;
        });
        this.subscribeTo("getConnectionsAsEntities").subscribe((c: Entity[]) => this.connections = c);
    }

    private getAddresses() {
        const sourceSelection = this.sourceProvider.getTreeViewSelection();
        const items = sourceSelection.length > 0 ? sourceSelection : this.sourceProvider.flattenedData;
        return items.flatMap(this.getLocations);
    }

    getNewLinkedContract = (dateRange: DateRange): LinkedContract => ({
        contractId: this.data.contract.contract.contractId,
        linkId: uuid(),
        dateRange: dateRange
    })

    sourceSelectionUpdated(sources: SourceInfo[]) {
        this.ngZone.runOutsideAngular(() => {
            this.addresses = this.getAddresses();
            this.sources = this.sourceProvider.sourceSelectionAfterCleanup();
        });
    }

    private getLocations(item: TreeViewItem): Location[] {
        switch (item.info.type) {
            case EntityType.organisation:
                return item.info.source.organisation.locations;
            case EntityType.location:
                return [item.info.source.location];
            case EntityType.connection:
                return [item.parent.info.source.location];
            case EntityType.meter:
                return [item.parent.parent.info.source.location];
        }
    }

    linkContract() {
        const connectionCommands = this.sources.connectionIds.map(connectionId => {
            const linkedContract = this.getNewLinkedContract(this.data.dateRange);
            const connection = this.connections.find(conn => conn.connection.connectionId === connectionId);

            if (!connection) {
                throw new Error(`Connection with ID ${connectionId} not found.`);
            }

            return {
                organisationId: connection.organisation.organisationId,
                locationId: connection.location.locationId,
                connectionId: connectionId,
                linkedContract: linkedContract
            };
        });

        const locationCommands = this.sources.locationIds.flatMap(locationId => {
            const linkedContract = this.getNewLinkedContract(this.data.dateRange);
            const location = this.addresses.find(loc => loc.locationId === locationId);
            if (!location) {
                throw new Error(`Location with ID ${locationId} not found.`);
            }

            const matchingConnections = location.connections
                .map(conn => this.connections.find(extConn => extConn.connection.connectionId === conn.connectionId))
                .filter(extConn => extConn && extConn.connection.info.connectionType === this.data.contract.contract.contractData.connectionType);

            if (matchingConnections.length === 0) {
                return [];
            }

            return matchingConnections.map(connection => ({
                organisationId: connection.organisation.organisationId,
                locationId: location.locationId,
                connectionId: connection.connection.connectionId,
                linkedContract: linkedContract
            }));
        });
        const allConnectionCommands = [...connectionCommands, ...locationCommands];

        const organisationCommands = this.sources.organisationIds.map(organisationId => {
            const linkedContract = this.getNewLinkedContract(this.data.dateRange);

            return {
                organisationId: organisationId,
                linkedContract: linkedContract
            };
        });

        this.sendCommand("com.flowmaps.api.organisation.LinkContract", <LinkContract> {
            connectionCommands: allConnectionCommands,
            organisationCommands: organisationCommands,
        });
    }
}

export class LinkContractInBulkData {
    contract: ContractEntity;
    dateRange?: DateRange;
}