import {Component, Input, OnInit} from '@angular/core';
import {View} from "../../../../common/view";
import {UpsertAPIkeyData} from "../api-keys-overview/api-keys-overview.component";
import {uuid} from "../../../../common/utils";
import {AppCommonUtils} from "../../../../common/app-common-utils";

@Component({
    selector: 'app-api-key-details',
    templateUrl: './api-key-details.component.html',
    styleUrls: ['./api-key-details.component.css']
})
export class ApiKeyDetailsComponent extends View implements OnInit {
    @Input() data: UpsertAPIkeyData;
    isNew: boolean = false;

    ngOnInit(): void {
        if (!this.data.keyId) {
            this.isNew = true;
            this.data.keyId = uuid();
        }
    }

    updateInfo = () => {
        this.sendCommand("com.flowmaps.api.user.api.GenerateApiKey", this.data, () => {
            AppCommonUtils.registerSuccess("You have successfully created your API key");
        })
    }
}