import {HandleQuery} from "../common/handle-query";
import {Observable, of} from "rxjs";
import {DataQueryFilters} from "../views/measurements-component/measurements-handler.component";
import {StandaloneHandler} from "../common/standalone-handler";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class AppMeasurementsHandler extends StandaloneHandler {

    @HandleQuery("getChartDataQueryFilters")
    getChartDataQueryFilters(): Observable<DataQueryFilters> {
        return of(null);
    }
}