import {Component, Input} from '@angular/core';
import {AppContext} from "../../../../app-context";
import {View} from "../../../../common/view";
import {ApiKey, DeleteApiKey, Secret} from "@flowmaps/flowmaps-typescriptmodels";
import {ApiKeyDetailsComponent} from "../api-key-details/api-key-details.component";
import {UpsertAPIkeyData} from "../api-keys-overview/api-keys-overview.component";
import {AppCommonUtils} from "../../../../common/app-common-utils";
import {ApiKeyResponseComponent} from "../api-key-response/api-key-response.component";

@Component({
    selector: 'app-api-keys-overview-item',
    templateUrl: './api-keys-overview-item.component.html',
    styleUrls: ['./api-keys-overview-item.component.css']
})
export class ApiKeysOverviewItemComponent extends View {
    appContext = AppContext;
    @Input() apiKey: ApiKey;

    openKeyDetails = () => this.openModal(ApiKeyDetailsComponent, <UpsertAPIkeyData>{
        keyId: this.apiKey.keyId,
        description: this.apiKey.description
    });

    cycleApiKey = () => {
        this.sendCommand("com.flowmaps.api.user.api.GenerateApiKey", <UpsertAPIkeyData>{
                keyId: this.apiKey.keyId,
                description: this.apiKey.description
            },
            (response: Secret) => {
                this.openModal(ApiKeyResponseComponent, response, {backdrop: 'static'})
                AppCommonUtils.registerSuccess("The token for this API key has been updated successfully.");
            }
        )
    }

    deleteApiKey = () => {
        var command = {
            userId: AppContext.userProfile.userId,
            keyId: this.apiKey.keyId
        }
        this.sendCommand("com.flowmaps.api.user.api.DeleteApiKey", <DeleteApiKey>command);
    }
}