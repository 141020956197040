import {Component, inject, OnInit} from '@angular/core';
import {AppContext} from '../../app-context';
import {AuthService} from "../../utils/auth.service";
import {Handler} from "src/app/common/handler";
import {View} from "src/app/common/view";
import {take} from "rxjs";
import {WebsocketService} from "../../common/websocket.service";
import {UiUpdate} from '@flowmaps/flowmaps-typescriptmodels';
import {publishEvent, subscribeTo} from "../../common/app-common-utils";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
@Handler()
export class HomeComponent extends View implements OnInit {
    context = AppContext;
    private authService: AuthService = inject(AuthService);
    private socketService: WebsocketService<UiUpdate> = inject(WebsocketService<UiUpdate>);

    ngOnInit(): void {
        const subscription = subscribeTo("com.flowmaps.api.user.GetUserProfile");
        subscription.pipe(take(1)).subscribe({
            next: userProfile => {
                if (userProfile) {
                    if (!AppContext.isHeadlessChrome()) {
                        this.socketService.initialise("api/updates", update => publishEvent(update.type, update));
                    }
                } else {
                    this.authService.navigateToLogin();
                }
            },
            error: () => this.authService.signOut()
        });
        subscription.subscribe(userProfile => AppContext.setUserProfile(userProfile));
    }
}
