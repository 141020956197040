import {Component, inject, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ChartDatasetExtended} from "../base-measurement-chart";
import {DataType, LocationMeasurementsResult, PortfolioMeasurementsResult} from "@flowmaps/flowmaps-typescriptmodels";
import {ChartOptions, ChartOptionType} from "../../dashboard/dashboard.types";
import {DashboardContext} from "../../dashboard/dashboard.context";
import {lodash} from "../../../common/utils";
import {AppContext} from "../../../app-context";
import {MeasurementsDataProvider} from "../../../utils/measurements-data-provider";
import {Observable} from "rxjs";
import {PaginationComponent} from "../../../common/pagination/pagination.component";
import {ActiveElement, Chart, ChartEvent} from "chart.js";
import {Entity, EntityType} from "../../../handlers/entity";
import {TranslateDirective} from "../../../common/utils/translate.directive";
import {View} from "../../../common/view";
import {ChartModalOptions, ChartUtilsService} from "../chart-utils.service";
import {DataQueryFilters} from "../../measurements-component/measurements-handler.component";
import {ChartDataProvider} from "../../../utils/chart-data-provider";
import {Handler} from "../../../common/handler";

@Component({
    template: "",
})
@Handler()
export class EntityPerformanceChartComponent extends View implements OnInit {
    chartUtils = inject(ChartUtilsService);
    _direction: 'ASC' | 'DESC' = 'DESC';
    weatherTypes: DataType[] = [];
    records: PerformanceRecord[] = [];
    perSquareMeterEnabled = false;
    currentPageRecords: PerformanceRecord[] = [];

    dataProvider: MeasurementsDataProvider<any>;
    chartDataProvider: ChartDataProvider = new ChartDataProvider();
    options: ChartOptions;
    modalOptions: ChartModalOptions = {
        fullScreen: false,
    }

    entityType = (): EntityType => null;
    possibleDataTypes = (): DataType[] => null;
    createData = (result: PortfolioMeasurementsResult | LocationMeasurementsResult): Observable<PerformanceRecord[]> => null;
    getAllEntities = (): Observable<Entity[]> => null;
    refresh = () => null;
    setDataProvider = (d: DataQueryFilters) => null;
    navigateToEntity: ((event: ChartEvent, elements: ActiveElement[], chart: Chart) => void) | boolean = false;
    onHover: ((event: ChartEvent, elements: ActiveElement[], chart: Chart) => void) | boolean = false;

    @ViewChild('container', {read: ViewContainerRef}) container: ViewContainerRef;
    @ViewChild("pagination") pagination: PaginationComponent<any>;

    @Input() dataType: DataType;
    @Input() showInReport: boolean;
    @Input() pageSize: number = 10;
    @Input() showBySquareMeter: boolean;

    // TODO: What to do with this, maybe generate an unique ID for each View
    private uniqueId = lodash.uniqueId();
    getInputId = (id: string) => `${this.uniqueId}-${id}`;


    ngOnInit() {
        this.subscribeTo("getChartOptions", ChartOptionType.Performance).subscribe((opts: ChartOptions) => {
            opts.showAllDays = opts.showAllDays === undefined ? true : opts.showAllDays;
            opts.selectedDataType = opts.selectedDataType || DataType.electricityConsumption;
            opts.selectedDays = opts.selectedDays || [];
            this.options = opts;
        });
    }


    get getSelectedDataType(): DataType {
        return this.options.selectedDataType;
    }

    @Input()
    set data(modalOptions: ChartModalOptions) {
        this.modalOptions = modalOptions;
        if (this.modalOptions.fullScreen) {
            this.sendQuery("getChartDataQueryFilters").subscribe((d: DataQueryFilters) => {
                this.setDataProvider(d);
                this.dataProvider.ranges = DashboardContext.defaultRanges;
            });
        }
    }

    @Input()
    set sortingDirection(direction: 'ASC' | 'DESC') {
        this._direction = direction;
        this.refresh();
    }

    showBySquareMeterChange(showBySquareMeter: boolean) {
        this.options.showBySquareMeter = showBySquareMeter;
        this.refresh();
    }

    measurementTypes = (): DataType[] => {
        if (this.getSelectedDataType === DataType.electricityConsumption) {
            return [DataType.electricityConsumption, DataType.electricityConsumptionOffPeak];
        }
        if (this.getSelectedDataType === DataType.electricityFeedIn) {
            return [DataType.electricityFeedIn, DataType.electricityFeedInOffPeak];
        }
        if (this.getSelectedDataType === DataType.electricityConsumptionReactive) {
            return [DataType.electricityConsumptionReactive, DataType.electricityConsumptionReactiveOffPeak,
                DataType.electricityFeedInReactive, DataType.electricityFeedInReactiveOffPeak];
        }
        return [this.getSelectedDataType];
    };

    measurementUnit = (): string => `${DashboardContext.getMeasurementUnit(this.getSelectedDataType)}${this.showPerSquareMeter() ? '/m²' : ''}`;

    measurementTypeChanged = (m: DataType) => {
        this.options.selectedDataType = m;
        this.refresh();
    }

    setData(result: PortfolioMeasurementsResult | LocationMeasurementsResult) {
        this.createData(result).subscribe(data => {
            let dataSorted = lodash.sortBy(data, a => {
                const value = a.value ? a.value : 0;
                const estimatedValue = a.estimatedValue ? a.estimatedValue : 0;
                return value + estimatedValue;
            });
            if (this._direction === "DESC") {
                dataSorted = dataSorted.reverse();
            }
            this.records = dataSorted;
            this.applyPaging(dataSorted.slice(0, this.pageSize));
        });
    }

    applyPaging = (items: PerformanceRecord[]) => {
        this.currentPageRecords = items;
        const estimatedData = items.map(a => a.estimatedValue);
        this.chartDataProvider.emit({
            labels: items.map(d => d.label),
            datasets: [{
                entityId: null,
                measurementType: this.getSelectedDataType,
                dataset: this.getDataset(false, items.map(a => a.value))
            }].concat(estimatedData.some(a => a > 0) ? [{
                entityId: null,
                measurementType: this.getSelectedDataType,
                dataset: this.getDataset(true, estimatedData)
            }] : [])
        });
    }

    private getDataset = (estimated: boolean, data: number[]): ChartDatasetExtended => {
        const color = AppContext.getChartColorForMeasurementAsString(this.getSelectedDataType);
        const label = this.measurementName(this.getSelectedDataType);
        return {
            label: estimated ? `${label} (${TranslateDirective.getTranslation("estimated", true)})` : label,
            data: data,
            backgroundColor: estimated ? "#FFFFFF" : color,
            borderColor: color,
            borderWidth: estimated ? 1 : 0,
            measurementType: this.getSelectedDataType,
            tooltip: {
                formatter: this.chartUtils.getCustomTooltipFormatter(this.getSelectedDataType, this.showPerSquareMeter()),
                labelOverride: this.measurementName(this.getSelectedDataType),
                data: data,
                estimated: estimated
            }
        }
    }

    dropdownFormatter = (measurementType: DataType) => this.measurementName(measurementType, false);

    measurementName = (measurementType: DataType, translate: boolean = true): string => {
        return AppContext.entityPerformanceMeasurementName(measurementType, translate);
    }

    showPerSquareMeter = (): boolean => {
        const showBySquareMeter = this.showBySquareMeter !== undefined ? this.showBySquareMeter : this.options.showBySquareMeter;
        return this.perSquareMeterEnabled && showBySquareMeter;
    }
}

export interface PerformanceRecord {
    entityId: string;
    value: number;
    estimatedValue: number;
    label: string;
}