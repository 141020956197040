import {Component, Input} from '@angular/core';
import {Secret} from "@flowmaps/flowmaps-typescriptmodels";
import {copyToClipboard} from "../../../../common/utils";
import {AppCommonUtils} from "../../../../common/app-common-utils";
import {View} from "../../../../common/view";
import {downloadAny} from "../../../../common/download.utils";

@Component({
    selector: 'app-api-key-response',
    templateUrl: './api-key-response.component.html',
    styleUrls: ['./api-key-response.component.css']
})
export class ApiKeyResponseComponent extends View {
    @Input() data: Secret;

    copyValue = (value: string) => copyToClipboard(value).subscribe({
        error: () => console.error("Failed to copy value to clipboard: " + value),
        complete: () => {
            AppCommonUtils.registerSuccess("Value copied to clipboard!");
        }
    });

    downloadKey = () => downloadAny(
        JSON.stringify(this.data.secret), `${this.data.secret.keyId}.json`, "application/json")
}
