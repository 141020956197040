import {ChangeDetectorRef, Component, inject, Input} from '@angular/core';
import {AggregatedDataPoint, BuildingType, DataType, Location} from "@flowmaps/flowmaps-typescriptmodels";
import {EnergyCompassUtils} from "../../charts/energy-compass-chart/energy-compass.utils";
import lodash from "lodash";
import {MeterMeasurementsDataProvider} from "../../../utils/meter-measurements-data-provider";
import moment from "moment";
import {RefdataUtils} from "../../refdata/refdata-utils";
import {interpolate} from "../../../common/utils";
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";

@Component({
    selector: 'app-location-energy-compass',
    templateUrl: './location-energy-compass.component.html',
    styleUrls: ['./location-energy-compass.component.scss']
})
@Handler()
export class LocationEnergyCompassComponent extends View {
    refDataUtils = RefdataUtils;
    _location: Location;
    buildingType: BuildingType;

    values: Record[] = [];

    measurements: DataType[] = [DataType.electricityConsumption, DataType.electricityConsumptionOffPeak];

    private changeDetector: ChangeDetectorRef = inject(ChangeDetectorRef);

    @Input()
    set dataProvider(dataProvider: MeterMeasurementsDataProvider) {
        const currentYear = moment().year();
        dataProvider.subscribeToData(d => {
            setTimeout(() => {
                const area = RefdataUtils.getLocationArea(this._location);
                const values = Object.entries(d.currentData.totalsPerYear.measurements)
                    .flatMap(e =>
                        e[1].map(dataPoint => (<AggregatedMeasurementDataPoint>{
                            timeRange: dataPoint.timeRange,
                            value: dataPoint.value,
                            measurementType: e[0] as DataType
                        }))
                    );
                this.values = Object.entries(lodash.groupBy(values, v => v.timeRange.start))
                    .map(e => ({
                        year: moment(e[0]).year(),
                        value: lodash.round(lodash.sum(e[1].map(e =>
                            EnergyCompassUtils.getValueWithFactor(e.measurementType, [e.value]))) / area, 1)
                    }))
                    .filter(r => r.year < currentYear)
                    .sort(RefdataUtils.locationEnergyCompassYearsComparator.compare);
                this.changeDetector.detectChanges();
            }, 0);
        });
    }

    @Input()
    set location(location: Location) {
        this._location = location;
        if (location && location.info.buildingType) {
            this.sendQuery("getBuildingType", location.info.buildingType).subscribe(b => this.buildingType = b);
        }
    }

    get parisProofValue(): number {
        return this.buildingType?.info.efficiencyScale["ParisProof"] || 0;
    }

    getBuildingBackgroundStyle(value: number): number {
        return Math.max(0, Math.min(80, 100 - interpolate(value, this.minValue, this.maxValue, 0, 100)));
    }

    get maxValue(): number {
        return lodash.max(this.values.map(v => v.value).concat(this.parisProofValue));
    }

    get minValue(): number {
        return lodash.min(this.values.map(v => v.value).concat(this.parisProofValue));
    }

    get noDataText(): string {
        if (!this._location) {
            return "No location selected";
        }
        if (!RefdataUtils.getLocationArea(this._location)) {
            return "No area defined, consumption per m² is unavailable";
        }
        if (this.values.length === 0) {
            return "No electricity consumption data available for this location";
        }
        return "";
    }
}

interface AggregatedMeasurementDataPoint extends AggregatedDataPoint {
    measurementType: DataType;
}

interface Record {
    year: number;
    value: number;
}
