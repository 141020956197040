import {AggregatedDataPoint, DataType, MeasurementsResult} from "@flowmaps/flowmaps-typescriptmodels";
import {DashboardTime, Slot} from "../../utils/measurements-data-provider";
import {AppContext} from "../../app-context";
import moment from "moment/moment";
import lodash, {cloneDeep} from "lodash";
import {DashboardContext, DateTimeSlot} from "../dashboard/dashboard.context";
import timeslots from "../../resources/timeslots.json";

export class Measurements {
    private static timeSlots = timeslots as TimeSlots;
    data: MeasurementsResult;

    constructor(data: MeasurementsResult, private measurementType: DataType, private dateRange: DashboardTime) {
        this.data = data;
    }

    groupMeasurements = () => this.groupByTimeFrames(this.dataPointsToChartData(Object.entries(this.data.measurements)
            .filter(([key, value]) => key === this.measurementType ? value : [])
            .flatMap(([key, value]) => value[1])
            .filter(a => a)),
        this.dateRange, AppContext.getAggregationMethod(this.measurementType)).data

    private groupByTimeFrames(data: ChartSimpleData[], dateRange: DashboardTime, aggregationMethod: (data: number[]) => number = lodash.sum): GroupedData {
        const slots = this.getSlots(dateRange);
        return {
            labels: slots.map(s => s.label),
            data: this.appendDataToSlots(data, slots).map(e => aggregationMethod(e.values))
        };
    }

    private getSlots(dateRange: DashboardTime) {
        const startDate = moment(dateRange.start);
        const endDate = moment(dateRange.end);
        const foundSlot = Measurements.timeSlots[dateRange.resolution];
        return lodash.range(0, endDate.diff(startDate, foundSlot.unit, false), foundSlot.amount).map(() => {
            const startTime = startDate.valueOf();
            const label = startDate.clone().local().locale(AppContext.getPreferredLanguage()).format(DashboardContext.getFormat(foundSlot.unit));
            const header = startDate.clone().local().locale(AppContext.getPreferredLanguage()).format(DashboardContext.getTableHeaderFormat(foundSlot.unit));
            const endTime = startDate.add(foundSlot.amount, foundSlot.unit).valueOf();

            return <Slot>{
                startTime: startTime,
                endTime: endTime,
                label: label,
                tableHeader: header,
                values: []
            }
        });
    }

    private appendDataToSlots(data: ChartSimpleData[], slots: Slot[]): Slot[] {
        const s = cloneDeep(slots);
        data.forEach(r => {
            const index = s.findIndex(s => lodash.inRange(r.x, s.startTime, s.endTime));
            if (index > -1) {
                s[index].values.push(r.y);
            }
        });
        return s;
    }

    private dataPointsToChartData(data: AggregatedDataPoint[]): ChartSimpleData[] {
        return data ? data.map(r => ({
            x: moment(r.timeRange.start).valueOf(),
            y: r.value
        })) : [];
    }
}

interface TimeSlots {
    [key: string]: {
        unit: DateTimeSlot;
        amount: number;
    }
}

interface GroupedData {
    labels: string[];
    data: number[];
}

interface ChartSimpleData {
    x: number;
    y: number;
}