import {AfterViewInit, Component, Input, OnInit, TemplateRef, Type, ViewChild, ViewContainerRef} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {HandleQuery} from "../../common/handle-query";
import {ChartOptions, ChartOptionType} from "../dashboard/dashboard.types";
import {mergeMap, Observable, of, ReplaySubject, Subject, take} from "rxjs";
import lodash, {cloneDeep} from "lodash";
import {DashboardContext} from "../dashboard/dashboard.context";
import {AppContext} from "../../app-context";
import {
    ContractMeasurementResult,
    DayOfWeekMeasurementsResult,
    GetContractMeasurements,
    GetDayOfWeekMeasurements,
    GetLocationMeasurements,
    GetPortfolioMeasurements,
    LocationMeasurementsResult,
    PortfolioMeasurementsResult,
    SourceIds,
    TimeResolution
} from "@flowmaps/flowmaps-typescriptmodels";
import {openModal, sendQuery} from "../../common/app-common-utils";
import moment from "moment";
import {entityToSourceInfo, SourceInfo} from "../../utils/source-providers/sources-provider";
import {EntityType} from "../../handlers/entity";
import {HandleEvent} from "../../common/handle-event";
import {DashboardTime} from "../../utils/measurements-data-provider";
import {ChartModalOptions} from "../charts/chart-utils.service";
import {HandleCommand} from "../../common/handle-command";
import {AnimationTypes} from "../../common/modal/modal";

@Component({
    selector: 'app-measurements-handler',
    templateUrl: './measurements-handler.component.html',
    styleUrls: ['./measurements-handler.component.scss']
})
@Handler()
export class MeasurementsHandlerComponent extends View implements OnInit, AfterViewInit {
    @Input() data: MeasurementsHandlerComponentData;
    @ViewChild('container', {read: ViewContainerRef}) container: ViewContainerRef;

    filtersSubject: Subject<DataQueryFilters>;

    ngOnInit() {
        this.filtersSubject = new ReplaySubject(1);
    }

    ngAfterViewInit() {
        this.setData = this.data;
    }

    set setData(data: MeasurementsHandlerComponentData) {
        this.data = data;
        if (data.component) {
            this.renderSubComponent(data.component);
        }
        this.filtersSubject.next({
            timeRange: this.dashboardTimeToQuery(this.data.timeRange),
            sources: this.data.selectedSources
        });
    }

    @HandleEvent("sourceSelectionChange")
    sourceSelectionChange(selectedSources: SourceInfo[]) {
        this.data.selectedSources = selectedSources;
        this.filtersSubject.pipe(take(1)).subscribe(f => this.filtersSubject.next((<DataQueryFilters>{
            ...f,
            sources: this.data.selectedSources
        })));
        if (this.data.chartModalData?.fullScreen) {
            return false;
        }
    }

    @HandleEvent("timeChanged")
    timeChanged(time: DashboardTime, event: CustomEvent) {
        this.data.timeRange = time;
        this.filtersSubject.pipe(take(1)).subscribe(f => this.filtersSubject.next((<DataQueryFilters>{
            ...f,
            timeRange: this.dashboardTimeToQuery(this.data.timeRange)
        })));
        if (this.data.chartModalData?.fullScreen) {
            event.stopPropagation();
        }
    }

    @HandleQuery("getChartOptions")
    getChartOptions(type: ChartOptionType): Observable<ChartOptions> {
        if (lodash.isEmpty(type)) {
            console.warn("Dashboard chart option type is required to get chart options");
            return of(null);
        }
        const chartOption = (this.data.chartOptions || {})[type];
        return of(chartOption || DashboardContext.defaultDashboard().info.chartOptions[type]);
    }

    @HandleQuery("getChartDataQueryFilters")
    getChartDataQueryFilters(): Observable<DataQueryFilters> {
        return this.filtersSubject;
    }

    @HandleQuery("getContractMeasurements")
    getContractMeasurements(): Observable<ContractMeasurementResult> {
        return this.subscribeTo("getChartDataQueryFilters").pipe(mergeMap((filters: DataQueryFilters) => {
            return filters.timeRange && filters.sources ? sendQuery("com.flowmaps.api.measurements.GetContractMeasurements", <GetContractMeasurements>{
                timeRange: filters.timeRange,
                sourceIds: this.asSourceIds(this.cleanupSelection(this.data.selectedSources)),
                resolution: filters.timeRange.resolution,
                unrounded: ![TimeResolution.year, TimeResolution.month].includes(filters.timeRange.resolution)
            }) : of(<ContractMeasurementResult>{
                co2measurements: {
                    measurements: {},
                    estimatedMeasurements: {}
                },
                costsMeasurements: []
            });
        }));
    }

    @HandleQuery("getDayOfWeekMeasurements")
    getDayOfWeekMeasurements(): Observable<DayOfWeekMeasurementsResult> {
        return this.subscribeTo("getChartDataQueryFilters").pipe(mergeMap((filters: DataQueryFilters) =>
            filters.timeRange && filters.sources && moment(filters.timeRange.end).diff(moment(filters.timeRange.start), "day") < 367
                ? sendQuery("com.flowmaps.api.measurements.GetDayOfWeekMeasurements", <GetDayOfWeekMeasurements>{
                    timeRange: filters.timeRange,
                    sources: this.asSourceIds(this.cleanupSelection(this.data.selectedSources))
                }) : of({measurements: {}})));
    }

    @HandleQuery("getPortfolioMeasurements")
    getPortfolioMeasurements(): Observable<PortfolioMeasurementsResult> {
        return this.subscribeTo("getChartDataQueryFilters").pipe(mergeMap((filters: DataQueryFilters) =>
            filters.timeRange && filters.sources && moment(filters.timeRange.end).diff(moment(filters.timeRange.start), "day") < 367
                ? sendQuery("com.flowmaps.api.measurements.GetPortfolioMeasurements", <GetPortfolioMeasurements>{
                    resolution: filters.timeRange.resolution,
                    timeRange: filters.timeRange,
                    sources: this.asSourceIds(this.cleanupSelection(this.data.selectedSources))
                })
                : of({})));
    }

    @HandleQuery("getLocationMeasurements")
    getLocationMeasurements(): Observable<LocationMeasurementsResult> {
        return this.subscribeTo("getChartDataQueryFilters").pipe(mergeMap((filters: DataQueryFilters) =>
            filters.timeRange && filters.sources && moment(filters.timeRange.end).diff(moment(filters.timeRange.start), "day") < 367
                ? sendQuery("com.flowmaps.api.measurements.GetLocationMeasurements", <GetLocationMeasurements>{
                    resolution: filters.timeRange.resolution,
                    timeRange: filters.timeRange,
                    locationIds: this.asSourceIds(this.cleanupSelection(this.data.selectedSources)).locationIds
                }) : of({})));
    }

    @HandleCommand("openChartInModal")
    openChartInModal(command: MeasurementsHandlerOpenModalCommand): void {
        this.sendQuery("getChartDataQueryFilters").subscribe((f: DataQueryFilters) => {
            const modalOptions = cloneDeep(command.chartModalData);
            modalOptions.fullScreen = true;
            const data = <MeasurementsHandlerComponentData>{
                chartOptions: command.chartOptions,
                component: command.component,
                chartModalData: modalOptions,
                timeRange: f.timeRange,
                selectedSources: f.sources
            };
            openModal(MeasurementsHandlerComponent, data, command.container, {
                cssClass: "modal-dialog-centered modal-xl d-flex align-items-stretch",
                style: "height: 90vh; min-height: 90vh; width: 90vw; min-width: 90vw;",
                animation: AnimationTypes.zoomIn
            });
        });
    }

    private asSourceIds = (sourceInfo: SourceInfo[]): SourceIds => {
        return {
            organisationIds: sourceInfo.filter(s => s.type === EntityType.organisation).map(s => s.id),
            locationIds: sourceInfo.filter(s => s.type === EntityType.location).map(s => s.id),
            connectionIds: sourceInfo.filter(s => s.type === EntityType.connection).map(s => s.id),
            meterIds: sourceInfo.filter(s => s.type === EntityType.meter).map(s => s.id)
        }
    }

    private cleanupSelection = (selection: SourceInfo[]): SourceInfo[] => {
        const selectedIds = selection.map(s => s.id);
        let toBeDeleted: string[] = [];
        for (const sourceInfo of selection) {
            const parent = sourceInfo.source.getParentAsEntity();
            if (parent) {
                if (selectedIds.includes(parent.getEntityId())) {
                    toBeDeleted.push(sourceInfo.id);
                }
                if (parent.getChildIds().length > 1 && parent.getChildIds().every(c => selectedIds.includes(c))) {
                    toBeDeleted = toBeDeleted.concat(parent.getChildIds());
                    selection.push(entityToSourceInfo(parent));
                }
            }
        }
        if (toBeDeleted.length) {
            const newSelection = lodash.uniqBy(selection.filter(s => !toBeDeleted.includes(s.id)), s => s.id);
            return this.cleanupSelection(newSelection);
        }
        return selection;
    }

    private renderSubComponent(component: Type<unknown>) {
        const comp = component;
        if (comp instanceof TemplateRef) {
            this.container.createEmbeddedView(comp);
        } else {
            const componentRef = this.container.createComponent(comp);
            const component = componentRef.instance;
            component['data'] = this.data.chartModalData;
        }
    }

    private dashboardTimeToQuery = (time: DashboardTime): DashboardTime => {
        if (!time) {
            return null;
        }
        const timeRange = AppContext.timeRangeToQuery(time);
        return {
            start: timeRange.start,
            end: timeRange.end,
            label: time.label,
            resolution: time.resolution
        };
    }
}

export interface DataQueryFilters {
    timeRange: DashboardTime;
    sources: SourceInfo[];
}

export interface MeasurementsHandlerOpenModalCommand {
    chartOptions?: { [P in ChartOptionType]?: ChartOptions };
    component?;
    chartModalData?: ChartModalOptions;
    container: ViewContainerRef;
    modalClasses?: string;
}

export interface MeasurementsHandlerComponentData {
    chartOptions?: { [P in ChartOptionType]?: ChartOptions };
    timeRange?: DashboardTime;
    selectedSources?: SourceInfo[];
    component?;
    chartModalData?: ChartModalOptions;
}