import {AfterViewInit, Component} from '@angular/core';
import {View} from "../../../../common/view";
import {ApiKey, Secret} from "@flowmaps/flowmaps-typescriptmodels";
import {AppContext} from "../../../../app-context";
import {Observable} from "rxjs";
import {ApiKeyDetailsComponent} from "../api-key-details/api-key-details.component";
import {ApiKeyResponseComponent} from "../api-key-response/api-key-response.component";

@Component({
    selector: 'app-api-keys-overview',
    templateUrl: './api-keys-overview.component.html',
    styleUrls: ['./api-keys-overview.component.css']
})
export class ApiKeysOverviewComponent extends View implements AfterViewInit {
    keys: ApiKey[] = [];
    command: UpsertAPIkeyData = {description: "", keyId: ""};
    query: Observable<ApiKey[]>
    protected readonly appContext = AppContext;

    generateApiKey = () => {
        this.openModal(ApiKeyDetailsComponent, <UpsertAPIkeyData>this.command, undefined,
            (result: Secret) => {
                this.command = {description: "", keyId: ""};
                if (result) {
                    this.openModal(ApiKeyResponseComponent, result, {backdrop: 'static'})
                }
            }
        )
    }

    ngAfterViewInit() {
        this.query = this.subscribeTo("getApiKeys");
    }

    trackByForRecord = (index: number, record: ApiKey) => record.keyId;
}

export interface UpsertAPIkeyData {
    description: string;
    keyId: string;
}